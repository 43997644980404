// Here you can add other styles
table {
  th {
    background-color: #1E978A !important;
    color: #fff !important;
  }
}

.btn-primary {
  color: #fff !important;
}

.btn-xs {
    font-size: 14px !important;
    padding: 5px 10px !important;
    line-height: 1 !important;
}

.btn-danger, .btn-success, .btn-warning, .btn-info, .btn-primary {
    color: #fff !important;
}
